import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from './Components/Header';
import FooterSection from './Components/Footer';
import Home from './pages/Home';
import LpcFerderatee from './pages/LpcFerderate';
import CaseStudies from './pages/CaseStudies';
import AboutUs from './pages/about';
import Board from './pages/board';
import Team from './pages/team';
import AllServices from './pages/allServices';
import EnhancedAccess from './Components/enhancedAccess/index';
import NotFound from './pages/notfound';
import Loader from './Components/Loader';
import Blogs from './pages/blogs';
import { HelmetProvider } from 'react-helmet-async';
import PrivacyPolicy from './pages/privacy-policy';

const App = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />;
  }
  const hideHeaderAndFooter = location.pathname === '/404';

  return (
    <>
      {!hideHeaderAndFooter && <Header />}
      <HelmetProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/index" element={<Home />} />
          <Route path="/lpcfederate" element={<LpcFerderatee />} />
          <Route path="/successstories" element={<CaseStudies />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/board" element={<Board />} />
          <Route path="/team" element={<Team />} />
          <Route path="/services" element={<AllServices />} />
          {/* <Route path="/blogs" element={<Blogs />} /> */}
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />

          <Route path="/servivedetails/:id" element={<EnhancedAccess />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </HelmetProvider>

      {!hideHeaderAndFooter && <FooterSection />}
    </>
  );
};

export default App;
