import React, { useEffect, useState } from 'react';
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import projectImg1 from '../../assets/images/Homeimages/project-img1.jpg';
import projectImg2 from '../../assets/images/Homeimages/project-img2.jpg';
import projectImg3 from '../../assets/images/Homeimages/project-img3.jpg';
import projectImg4 from '../../assets/images/Homeimages/project-img4.jpg';
import projectImg5 from '../../assets/images/Homeimages/project-img5.jpg';
import projectImg6 from '../../assets/images/Homeimages/project-img6.jpg';
import CounterSection from '../counterSection';
import TestimonialSection from '../testiminials';
import processLeftImg from '../../assets/images/Homeimages/sucwork.png';
import processLeftImg1 from '../../assets/images/Homeimages/succclaim.png';
import processLeftImg2 from '../../assets/images/Homeimages/sucpres.png';
import processLeftImg3 from '../../assets/images/Homeimages/sucrecep.png';
import CryptoJS from 'crypto-js';
import { Link } from 'react-router-dom';


const CaseStudy = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [bannerData, setbannerData] = useState([]);




  const encryptToken = (token, key, iv) => {
      const keyHex = CryptoJS.enc.Utf8.parse(key);
      const ivHex = CryptoJS.enc.Utf8.parse(iv);
      const encrypted = CryptoJS.AES.encrypt(token, keyHex, { iv: ivHex, mode: CryptoJS.mode.CBC });
      return encrypted.toString();
    };
  useEffect(() => {
      window.scrollTo(0, 0);
      const key = '8347383272422213';
      const iv = '1234567890123456';
      const token = 'Pass.lpc#webitaltech';
  
      const encryptedToken = encryptToken(token, key, iv);
      const fetchData = async () => {
        try {
          const response = await fetch(`https://localprimarycare.co.uk/api/Get-LPC-Data.php?action=getCaseStudies`,
            {
              method: 'GET',
              headers: {
                Authorization: encryptedToken,
                'Content-Type': 'application/json',
              },
              referrerPolicy: 'no-referrer',
            }
          );
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const resp = await response.json();
          if (resp.status === 'error') {
            console.error('Error:', resp.message);
          } else {
            setData(resp.data.data[0]);
            setData2(resp.data.data[1]);
            setData3(resp.data.data[2]);
            setData4(resp.data.data[3]);


            // console.log(resp);
            
          }
        } catch (err) {
          console.log(err.message);
        } finally {
          // setLoading(false);
        }
      };
      const fetchDataforbanner = async () => {
        try {
          const response = await fetch(`https://localprimarycare.co.uk/api/Get-LPC-Data.php?action=getSubBanners`,
            {
              method: 'GET',
              headers: {
                Authorization: encryptedToken,
                'Content-Type': 'application/json',
              },
              referrerPolicy: 'no-referrer',
            }
          );
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const resp = await response.json();
          if (resp.status === 'error') {
            console.error('Error:', resp.message);
          } else {
            setbannerData(resp.data.data[0]);


            // console.log(resp);
            
          }
        } catch (err) {
          console.log(err.message);
        } finally {
          // setLoading(false);
        }
      };
      fetchDataforbanner();
      fetchData();      
    }, []);

    const projects = [
        { img: projectImg1, category: 'Couple Problem', title: 'Mental Consult', description: 'Nostrud exercitation aute irure...', link: '/services' },
        { img: projectImg2, category: 'Individual', title: 'Personal Meeting', description: 'Gostrud exercitation aute irure...', link: '/services' },
        { img: projectImg3, category: 'Frustration', title: 'Depression', description: 'Eostrud exercitation aute irure...', link: '/services' },
        { img: projectImg4, category: 'Couple', title: 'Relationship', description: 'Nostrud exercitation aute irure...', link: 'services.html' },
        { img: projectImg5, category: 'Individual', title: 'Rude Behaviour', description: 'Gostrud exercitation aute irure...', link: '/services' },
        { img: projectImg6, category: 'Depressed', title: 'Grief & Loss', description: 'Eostrud exercitation aute irure...', link: '/services' },
    ];

    const cleanDescriptionText = (description) => {
      if (!description) return ''; // Ensure no errors if description is null/undefined
    
      try {
        // Parse the string to remove extra escaping
        const parsedDescription = JSON.parse(`"${description}"`);
    
        return parsedDescription
          .replace(/\?/g, "'")     // Replace misplaced question marks with single quotes
          .replace(/â€™/g, "'")     // Fix encoding issues for single quote
          .replace(/â€œ/g, '"')     // Fix encoding issues for double quote
          .replace(/â€/g, '"')      // Fix any other encoding issues
          .replace(/(\r\n|\n|\r)/g, '') // Remove line breaks
          .replace(/&nbsp;/g, ' ')  // Convert non-breaking spaces to regular spaces
          .trim();                  // Trim leading/trailing spaces
      } catch (error) {
        console.error("Error parsing description:", error);
        return description; // Return the original description if parsing fails
      }
    };


    
    return (
      <>
  
  {/* BANNER SECTION START */}
  <section className="project-banner sub-banner-section w-100 float-left d-flex align-items-center">
    <div className="container">
      <div className="sub-banner-inner-con text-center">
        <h1 data-aos="fade-up" data-aos-duration={700}>
        {bannerData?.title}
        </h1>
        <p data-aos="fade-up" data-aos-duration={700} dangerouslySetInnerHTML={{ __html: bannerData?.text}}>
   
        </p>
        <nav aria-label="breadcrumb" data-aos="fade-up" data-aos-duration={700}>
          <ol className="breadcrumb d-inline-block mb-0">
            <li className="breadcrumb-item d-inline-block">
              <Link to={"/"}>Home</Link>
            </li>
            <li
              className="breadcrumb-item active text-white d-inline-block"
              aria-current="page"
            >
             {bannerData?.title}            </li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
  {/* BANNER SECTION END */}

  {/* <section className="projects-section w-100 float-left padding-top padding-bottom light-bg">
            <div className="container">
                <div className="generic-title text-center">
                    <span className="small-text" data-aos="fade-up" data-aos-duration="700">Latest Projects</span>
                    <h2 data-aos="fade-up" data-aos-duration="700">Explore Our Latest Case Studies</h2>
                </div>
                <div className="projects-outer-con">
                    {projects.map((project, index) => (
                        <div className="project-box" key={index} data-aos="fade-up" data-aos-duration="700">
                            <div className="project-img">
                                <figure className="mb-0">
                                    <img src={project.img} alt={`project-img${index + 1}`} />
                                </figure>
                            </div>
                            <div className="project-detail">
                                <span className="d-block">{project.category}</span>
                                <h4>{project.title}</h4>
                                <p>{project.description}</p>
                                <Link to={project.link}><i className="fas fa-angle-right"></i></Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section> */}

<section className="process-main-section w-100 float-left pt-5 contact-main-sec pb-5">
            <div className="container">
            <div className="generic-title text-center">
              <h2 data-aos="fade-up" data-aos-duration="700">{data?.title}</h2>
          </div>
                <div className="process-inner-con">
          
                    <div className="process-right-sec">
  <ul className="mb-0 list-unstyled">
    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
      // 
    > 
      <div className="process-text-con " style={{color: "black"}} dangerouslySetInnerHTML={{ __html:cleanDescriptionText(data?.description)}}>
      {/* The staff and support are second to none at the workflow hub. They are polished, proficient, accessible, professional, and always there to offer advice and support.
      <br/>
      <br/>
      It is very reassuring knowing that if we are struggling with staffing issues, the workflow team are on hand to support with our documents. They work with our practice and set up clear guidance for all staff to follow.
      <br/>
      <br/>
      We have never had any issues with the work produced and will continue to use this excellent service.
      <br/>
      <br/>
      The document workflow hub has been a God send! Not just to GPs but also to our entire workforce. The time we have freed up for all staff to concentrate on other aspects of work has been vast. We also feel very assured our coding is 100% accurate and actions from letters are dealt with in a much more timely manner than they were before. Any issues we identify are dealt with quickly by LPC and having a small dedicated team at LPC that works for our practice we have found really works for us as they serve as a point of contact and we build relationships with them.
      <br/>
      <br/>
      We would like to say thank you for brilliant communication and service regarding the workflow hub - it has massively helped with our day to day workload, the letters are very efficiently actioned and it has made the working day much lighter for us all! */}
      </div>
    </li>
  
    {/* <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >

      <div className="process-text-con">
 
        <p>
        <b>Excellence:</b> We strive for the highest standards in all aspects of healthcare delivery.
        </p>
      </div>
    </li>

    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >

      <div className="process-text-con">
 
        <p>
        <b>Ethical:</b> We adhere to the highest moral principles in all our practices and decisions.
        </p>
      </div>
    </li>

    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >

      <div className="process-text-con">
  
        <p>
        <b>Transparency:</b> We maintain open, honest communication with patients and stakeholders.
        </p>
      </div>
    </li>

    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >

      <div className="process-text-con">
 
        <p>
        <b>Trust:</b> We build and nurture trust through reliability and integrity in all we do.
        </p>
      </div>
    </li> */}
  </ul>

</div>
<div className="process-left-sec" data-aos="fade-up" data-aos-duration="700">
                        <figure className="mb-0 ">
                            <img src={`https://localprimarycare.co.uk/public/images/case_study/${data?.image}`} alt="process-left-img" className='left-img-section-res'/>
                        </figure>
                    </div>  

                </div>
            </div>
        </section>

        <section  className="process-main-section w-100 float-left contact-main-sec pb-5" style={{background: "#156c5f"}}>
            <div className="container pt-5">
            <div className="generic-title text-center">
              <h2 data-aos="fade-up" data-aos-duration="700" className='text-white'>{data2?.title}</h2>
          </div>
                <div className="process-inner-con">
                <div className="process-left-sec" data-aos="fade-up" data-aos-duration="700">
                        <figure className="mb-0 ">
                            <img src={`https://localprimarycare.co.uk/public/images/case_study/${data2?.image}`} alt="process-left-img" />
                        </figure>
                    </div>  
                    <div className="process-right-sec">
  <ul className="mb-0 list-unstyled">
    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    > 
      <div className="process-text-con text-white mt-4" dangerouslySetInnerHTML={{ __html:cleanDescriptionText(data2?.description)}}>
      {/* The person that gets the most benefit is our Clinical Coding and QOF lead. But in addition both Myself and the partners agree that the work you are doing is just super for us and saving us a great deal of stress and worry. The detailed reports you share has helped us look at things differently and realise that with some minor tweaks and adjustments we can increase our income and improve coding knowledge across the teams - especially the clinical teams. We are delighted with the work so far. Not least as it takes away a burden from us with the assurance of it being done well, on time and with a greater understanding of individual patients care that crosses into other domains and is often missed. I wouldn't hesitate to endorse you wider if you ever needed it. */}
      </div>
    </li>
  </ul>

</div>


                </div>
            </div>
        </section>

        <section  className="process-main-section w-100 float-left padding-top pb-5 contact-main-sec">
            <div className="container">
            <div className="generic-title text-center">
              <h2 data-aos="fade-up" data-aos-duration="700">{data3?.title}</h2>
          </div>
                <div className="process-inner-con">
                <div className="process-left-sec" data-aos="fade-up" data-aos-duration="700">
                        <figure className="mb-0 ">
                            <img src={`https://localprimarycare.co.uk/public/images/case_study/${data3?.image}`} alt="process-left-img" className='right-img-section-res'/>
                        </figure>
                    </div>  
                    <div className="process-right-sec">
  <ul className="mb-0 list-unstyled">
    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    > 
      <div className="process-text-con " dangerouslySetInnerHTML={{ __html:cleanDescriptionText(data3?.description)}}>
      {/* We sincerely appreciate the excellent work that your team is doing processing prescriptions
      <br/>
      <br/>
      The huge demand on our telephones currently sadly sometimes overshadows the good work of the prescription hub as we don't see the levels you are taking from what would potentially be an even larger load. We appreciate your forward thinking of new ways of working of how your team can further support practices
      <br/>
      Communication with yourself re- patients concerns/complaints or anything needing some clarity is always approachable and responsive - thank you.
      <br/>
      <br/>
      Your team have been a fantastic help, was lovely to speak with. */}
      </div>
    </li>
  </ul>

</div>
                </div>
            </div>
        </section>


        <section  className="process-main-section w-100 float-left pt-5 pb-5 contact-main-sec">
            <div className="container">
            <div className="generic-title text-center">
              <h2 data-aos="fade-up" data-aos-duration="700">{data4?.title}</h2>
          </div>
                <div className="process-inner-con">
                <div className="process-left-sec " data-aos="fade-up" data-aos-duration="700">
                        <figure className="mb-0 ">
                            <img src={`https://localprimarycare.co.uk/public/images/case_study/${data4?.image}`} alt="process-left-img" />
                        </figure>
                    </div>  
                    <div className="process-right-sec">
  <ul className="mb-0 list-unstyled">
    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    > 
      <div className="process-text-con mt-4" dangerouslySetInnerHTML={{ __html:cleanDescriptionText(data4?.description)}}>
      </div>
    </li>
  </ul>

</div>
                </div>
            </div>
        </section>

   

<CounterSection/>
<TestimonialSection/>
      </>
    );
};

export default CaseStudy;
