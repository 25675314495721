import React, { useEffect, useState } from 'react';
import processLeftImg from '../../assets/images/Homeimages/process-left-img.png';
import '../../assets/bootstrap/bootstrap.min.css';
import '../../assets/css/aos.css';
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import './formcont.css'; // Import the new CSS file
import { MdCall } from "react-icons/md";
import { IoMdMailUnread } from "react-icons/io";
import { FaAddressBook } from "react-icons/fa";
import CryptoJS from 'crypto-js';


const ProcessContSection = () => {
    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        phone: '',
        subject: ''
    });
    const [isChecked, setIsChecked] = useState(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log('Form data:', formData);
    };

    const [data, setData] = useState(null);

    const encryptToken = (token, key, iv) => {
        const keyHex = CryptoJS.enc.Utf8.parse(key);
        const ivHex = CryptoJS.enc.Utf8.parse(iv);
        const encrypted = CryptoJS.AES.encrypt(token, keyHex, { iv: ivHex, mode: CryptoJS.mode.CBC });
        return encrypted.toString();
      };
    useEffect(() => {
        const key = '8347383272422213';
        const iv = '1234567890123456';
        const token = 'Pass.lpc#webitaltech';
    
        const encryptedToken = encryptToken(token, key, iv);
        const fetchData = async () => {
          try {
            const response = await fetch(`https://localprimarycare.co.uk/api/Get-LPC-Data.php?action=getContactDetails`,
              {
                method: 'GET',
                headers: {
                  Authorization: encryptedToken,
                  'Content-Type': 'application/json',
                },
                referrerPolicy: 'no-referrer',
              }
            );
    
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
    
            const resp = await response.json();
            if (resp.status === 'error') {
              console.error('Error:', resp.message);
            } else {
              setData(resp.data.data[0]);
              // console.log(resp);
              
            }
          } catch (err) {
            console.log(err.message);
          } finally {
            // setLoading(false);
          }
        };
    
        fetchData();
      }, []);
      const replaceSpecialCharacters = (text) => {
        if (!text) return text;
      
        
        return text
          .replace(/\?/g, "'")  
          .replace(/â€™/g, "'")  
          .replace(/â€œ/g, '"')  
          .replace(/â€/g, '"');  
      };
     
    return (
        <section id='contact' className="process-main-section w-100 float-left padding-top padding-bottom contact-main-sec">
            <div className="container">
                <div className="process-inner-con">
                    <div className="process-left-sec" data-aos="fade-up" data-aos-duration="700">
                        <figure className="mb-0">
                            <img src={processLeftImg} alt="process-left-img" />
                        </figure>
                    </div>
                    <div className="process-right-sec mt-3">
  <div className="generic-title">
  <h2 data-aos="fade-up" data-aos-duration="700">Contact Us</h2>
  </div>
  <ul className="mb-0 list-unstyled">
    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >
      <div className="process-img-sec">
        <figure>
        <MdCall size={30} color='#156c5f'/>
        </figure>
      </div>
      <div className="process-text-con">
        <h4>Telephone:</h4>
        <p>{data?.phone}</p>
      </div>
    </li>
    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >
      <div className="process-img-sec">
        <figure>
          <IoMdMailUnread size={27} color='#156c5f'/>
        </figure>
      </div>
      <div className="process-text-con">
        <h4>Email:</h4>
        <p>{data?.email}</p>
      </div>
    </li>
    <li
      data-aos="fade-up"
      data-aos-duration={700}
      className="aos-init aos-animate"
    >
      <div className="process-img-sec">
        <figure>
        <FaAddressBook  size={27} color='#156c5f'/>
        </figure>
      </div>
      <div className="process-text-con">
        <h4>Address:</h4>
        <p>
{data?.address}
        </p>
      </div>
    </li>
  </ul>

</div>

                </div>
            </div>
        </section>
    );
};

export default ProcessContSection;
