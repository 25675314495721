import Aos from 'aos';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../assets/bootstrap/bootstrap.min.css'
import '../assets/css/aos.css'
import '../assets/css/custom.css';
import '../assets/css/mobile.css';
import '../assets/css/owl.carousel.css';
import '../assets/css/custom-style.css';
import '../assets/css/responsive.css';
import '../assets/css/special-classes.css';
import 'aos/dist/aos.css';

const PrivacyPolicy = () => {
    
    useEffect(() => {
      window.scrollTo(0, 0);
      Aos.init();
    }, []);
  return (
<>
        {/* BANNER SECTION START */}
          <section className="sub-banner-section w-100 float-left d-flex align-items-center mb-5">
            <div className="container">
              <div className="sub-banner-inner-con text-center">
                <h1 data-aos="fade-up" data-aos-duration="700">Privacy Policy</h1>
                {/* <p data-aos="fade-up" data-aos-duration="700">Roidunt eget semper nec ruam sed hendrerit morbi aeu
                                feliseao augue <br>
                                pellentesue veniam morbi acer.</p> */}
                <nav aria-label="breadcrumb" data-aos="fade-up" data-aos-duration="700">
                  <ol className="breadcrumb d-inline-block mb-0">
                    <li className="breadcrumb-item d-inline-block"><Link to={"/"}>Home</Link></li>
                    <li className="breadcrumb-item active text-white d-inline-block" aria-current="page">Privacy Policy</li>
                  </ol>
                </nav>
              </div>
            </div>
          </section>
          {/* BANNER SECTION END */}
    <section className='container padding-top'>
    <div className="">
      
      <p className=''>
        This privacy policy applies between you as a user of Local Primary Care Federation services, and Local Primary Care Federation. This Privacy Notice also covers the users of our website. Local Primary Care Federation (hereafter LPC) takes the privacy of your information very seriously. This privacy policy applies to our use of any and all Data collected by us or provided by you in relation to your use of our services.
      </p>
      <p className="lead">
        Please read this Privacy Policy carefully.
      </p>

      {/* Section 1: Definitions and Interpretations */}
      <h3 className="mt-5">1. Definitions and Interpretations</h3>
      <p>
        <strong>1.a</strong> The following definitions are used:
      </p>
      <ul>
        <li><strong>Data</strong> - collectively all information that you submit to Local Primary Care Federation via any means, or data which we collect. This definition incorporates, where applicable, the definitions provided in the Data Protection Laws detailed below.</li>
        <li><strong>Processing</strong> - includes the collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure, or destruction of personal data.</li>
        <li><strong>Cookies</strong> - when accessing the website, a small text file may be placed on your computer when you visit certain parts of the Website and/or when you use certain features of the Website. Details of the cookies used by this Website are set out in the clause below (Cookies).</li>
        <li><strong>Data Protection Laws</strong> - any applicable UK law relating to the processing of personal Data, including but not limited to the UK General Data Protection Regulation (UK GDPR) and the Data Protection Act 2018.</li>
        <li><strong>Local Primary Care Federation</strong> - we or us:
          <ul>
            <li>a company incorporated in England and Wales with registered Company number 09348776.</li>
            <li>Registered office, Ams Medical Accountants, Floor 2, 9 Portland Street, Manchester, England, M1 3BE.</li>
            <li>ICO registration number ZA244667.</li>
          </ul>
        </li>
        <li><strong>LPC</strong> – may be used as the abbreviation for Local Primary Care Federation for ease of interpretation.</li>
        <li><strong>UK and EU Cookie Law</strong> - the Privacy and Electronic Communications (EC Directive) Regulations 2003 as amended by the Privacy and Electronic Communications (EC Directive) (Amendment) Regulations 2011 and the UK GDPR.</li>
        <li><strong>User or you</strong> - any third party that accesses services, or the Website and is not either (i) employed by Local Primary Care Federation and acting in the course of their employment or (ii) engaged as a consultant or otherwise providing services to Local Primary Care Federation in connection with the provision of such services.</li>
        <li><strong>Website</strong> - the website which you may access, <a href="https://lpcfederate.co.uk/index.html">https://lpcfederate.co.uk/index.html</a>, and any sub-domains of this site unless expressly excluded by their own terms and conditions.</li>
      </ul>
      <p>
        <strong>Context</strong> - In this privacy policy:
      </p>
      <ul>
        <li>unless the context requires a different interpretation, the singular includes the plural and vice versa</li>
        <li>references to sub-clauses, clauses, schedules or appendices are restricted to sub-clauses, clauses, schedules or appendices of this privacy policy</li>
        <li>any reference to a person includes firms, companies, government entities, trusts and partnerships</li>
        <li>"including" is understood to mean "including without limitation"</li>
        <li>reference to any statutory provision includes any modification or amendment of it</li>
        <li>the headings and sub-headings do not form part of this privacy policy.</li>
      </ul>

      {/* Section 2: Scope of this Privacy Notice */}
      <h3 className="mt-5">2. Scope of this Privacy Notice</h3>
      <p>
        <strong>2.a</strong> This privacy policy will apply only to the actions and procedures of LPC and to the data collection in respect to the Website, it does not extend to any websites that can be accessed from the LPC Website including, but not limited to, any links we may provide to social media websites.
      </p>
      <p>
        <strong>2.b</strong> For purposes of the applicable Data Protection Laws, LPC is the "data controller". This means that LPC determines the purposes for which, and the manner in which, your Data is processed.
      </p>

      {/* Section 3: Data Collected */}
      <h3 className="mt-5">3. Data Collected</h3>
      <p>
        We will collect data from you strictly in accordance with the principles set out within the Data Protection legislation expectations. These principles are in place to ensure that we are accountable, and that data processing will be:
      </p>
      <ul>
        <li><strong>Transparent</strong> – which means in a lawful, fair and transparent way.</li>
        <li><strong>Legitimate</strong> - we will collect data only for a specific purpose and only process it for that original intent.</li>
        <li><strong>Limited</strong> – only the amount of data required for the purposes identified will be collected.</li>
        <li><strong>Up to date</strong> – we will use all reasonable endeavours to ensure we have the more accurate and up to data information.</li>
        <li><strong>Retention</strong> – we will only keep your data for as long as it is needed unless we are required to retain it longer to fulfill a legal obligation.</li>
        <li><strong>Secure</strong> – all data we hold is held confidentially and with the highest possible levels of security.</li>
      </ul>
      <p>
        LPC may collect the following Data, which will be influenced by and dependent on the reasons we are engaging with you. Data collected will consist of both personal data and sensitive data, referred to as special category data.
      </p>
      <ul>
        <li>Basic details about you such as, name, address, date of birth, mobile and home telephone numbers</li>
        <li>Email address – if provided</li>
        <li>Gender</li>
        <li>Job title and employer</li>
        <li>Details relating to ethnic origin, cultural and religious belief – if provided</li>
        <li>Details of allergies, special needs such as communication requirements or accessibility considerations</li>
        <li>Contact we have had with you such as appointments and home visits</li>
        <li>Notes and reports about your health, physical and mental condition</li>
        <li>Details and records of your treatment and care</li>
        <li>Results of investigations, including x-rays and laboratory test results</li>
        <li>Relevant information from other people who care for you such as healthcare professionals (from within or without the NHS) and relatives</li>
        <li>Accident and Incident reporting</li>
        <li>Surveillance camera photographic data</li>
        <li>In the event of a Lasting Power of Attorney being in place we will record this</li>
        <li>When using the Website, we will also collect your IP address, operating system and web browser type and version.</li>
      </ul>

      {/* Section 4: How we collect data */}
      <h3 className="mt-5">4. How we collect data</h3>
      <p>
        <strong>4.a</strong> We may collect data directly from you:
      </p>
      <ul>
        <li>when you make general enquiries which require a response</li>
        <li>when you access services provided by us</li>
        <li>when you complete surveys (unless it is stated such survey responses are anonymised).</li>
      </ul>
      <p>
        <strong>4.b</strong> Data may be collected automatically when accessing electronic information. For example:
      </p>
      <ul>
        <li>when you access the website, we automatically collect your IP address, the date, times and frequency with which you access the Website and the way you use and interact with its content</li>
        <li>this information helps us to make improvements to Website content and navigation</li>
        <li>this data is harvested automatically via cookies, in line with the cookie settings on your browser. For more information about cookies, and how we use them on the Website, see the section below, headed "Cookies".</li>
      </ul>

      {/* Section 5: How we use your data */}
      <h3 className="mt-5">5. How we use your data</h3>
      <p>
        In keeping with UK GDPR and the Data Protection Act 2018, we may use your data in many ways such as those listed below to:
      </p>
      <ul>
        <li>ensure we have accurate, up to date contact information</li>
        <li>facilitate appointments and record keeping</li>
        <li>offer feedback and communications relating to treatment</li>
        <li>ensure you receive the most appropriate, safe and effective treatment</li>
        <li>allow appropriate Healthcare professionals to provide you with the range of care you may require</li>
        <li>provide anonymised information to support NHS recording relating to service provision expectations and requirements</li>
        <li>support internal audits as required by regulatory authorities</li>
        <li>provide information to regulatory authorities such as the Care Quality Commission</li>
        <li>ensure we meet the legal obligations in relation to healthcare record management</li>
        <li>properly investigate any concerns or complaints you may raise with us</li>
        <li>the event of an incident we may require sharing your data with a 3rd Party such as the Health & Safety Executive.</li>
      </ul>

      {/* Section 6: Legal Basis for data collection */}
      <h3 className="mt-5">6. Legal Basis for data collection</h3>
      <p>
        There are a range of lawful bases allowing the collection of personal data. Which basis LPC uses will be dependent on the services we are supporting you with.
      </p>
      <ul>
        <li><strong>Necessary for Contract</strong> – processing your data is necessary for the Patient Contract we hold with members of the Federation to support care.</li>
        <li><strong>Public Interest</strong> – to support education and training for healthcare professionals. Anonymised data may be used in training publications and training events.</li>
        <li><strong>Legitimate Interest</strong> – LPC may process your information for providing and improving the clinical services you access. We also use your data to contact you about booked appointments and ongoing care planning.</li>
        <li><strong>Legal Obligation</strong> – LPC collect and process your data to meet legal obligations for record management, including the archiving of your information held in your Patient record. We also collect information to meet our legal obligations agencies such as the Health & Safety Executive, the Care Quality Commission, and the Information Commissioners Office.</li>
        <li><strong>Common Law</strong> - We will also recognise your rights established under UK case law collectively known as the “Common Law Duty of Confidentiality”.</li>
      </ul>
      <p>
        The processing of personal data in the delivery of direct care and for providers’ administrative purposes in LPC practices and services and in support of direct care, is supported under the following Article 6 and 9 conditions of the UK General Data Protection Regulation:
      </p>
      <ul>
        <li>Article 6(1)(e) ‘…necessary for the performance of a task carried out in the public interest or in the exercise of official authority…’.</li>
        <li>Article 9(2)(h) ‘necessary for the purposes of preventative or occupational medicine for the assessment of the working capacity of the employee, medical diagnosis, the provision of health or social care or treatment or the management of health or social care systems and services…”.</li>
      </ul>

      {/* Section 7: Who do we share data with */}
      <h3 className="mt-5">7. Who do we share data with</h3>
      <p>
        <strong>7.a</strong> Your data is always kept secure and is shared only on a ‘need to know’ basis. Limited and proportionate sharing may occur with:
      </p>
      <ul>
        <li>Healthcare professionals – doctors, nurses, pharmacists, physiotherapists, occupational therapists both in clinical and domiciliary settings</li>
        <li>Healthcare administrative support staff</li>
        <li>Healthcare students undergoing training</li>
        <li>Laboratory staff</li>
        <li>Staff conducting clinical audits</li>
        <li>Visiting regulatory authorities such as the Care Quality Commission or the Health & Safety Executive.</li>
      </ul>
      <p>
        <strong>7.b</strong> Subject to sharing protocols and with your consent we may also share your data with:
      </p>
      <ul>
        <li>Social services</li>
        <li>Education services</li>
        <li>Local authorities</li>
        <li>Translation services should you require this support</li>
        <li>Voluntary sector providers.</li>
      </ul>
      <p>
        <strong>7.c</strong> There may be times when we share your information without your consent. For example:
      </p>
      <ul>
        <li>Where there is a risk of harm to you or to other people</li>
        <li>Where we believe that the reasons for sharing are so important that they override our obligation of confidentiality. (for example, to support investigation and prosecution of offenders or to prevent serious crime)</li>
        <li>When instructed to do so by a court order</li>
        <li>To control infectious diseases such as meningitis, tuberculosis or measles.</li>
        <li>If you are subject to the provisions of The Mental Health Act (1983) and there are circumstances where your ‘nearest relative’ must receive information even if you object.</li>
      </ul>

      {/* Section 8: Keeping data secure */}
      <h3 className="mt-5">8. Keeping data secure</h3>
      <p>
        <strong>8.a</strong> We use physical, technical and organisational measures to safeguard your Data, for example we:
      </p>
      <ul>
        <li>ensure all premises and facilities where data is stored are secure and adequately monitored</li>
        <li>provide information security training to staff and to commit to Data Quality supported through training and best practice</li>
        <li>ensure robust technology security is in place through the use of firewalls, password protection of files and two factor authentication on devices</li>
        <li>encryption of data</li>
        <li>encryption of surveillance camera data.</li>
        <li>payment details are encrypted using SSL technology (typically you will see a lock icon or green address bar (or both) in your browser when we use this technology.</li>
      </ul>
      <p>
        <strong>8.b</strong> Technical and organisational measures include measures to deal with any suspected data breach. If you suspect any misuse or loss or unauthorised access to your Data, please let us know immediately by contacting us via this e-mail address: <a href="mailto:localprimarycare@nhs.net">localprimarycare@nhs.net</a>.
      </p>
      <p>
        <strong>8.c</strong> If you would like more detailed information from Get Safe Online on how to protect your information and your computers and devices against fraud, identity theft, viruses and many other online problems, please visit <a href="https://www.getsafeonline.org">www.getsafeonline.org</a>. Get Safe Online is supported by HM Government and leading businesses.
      </p>

      {/* Section 9: Data retention */}
      <h3 className="mt-5">9. Data retention</h3>
      <p>
        Unless a longer retention period is required or permitted by law, we will only hold your Data on our systems for the period necessary to fulfil the purposes outlined in this privacy policy.
      </p>
      <p>
        UK GDPR Article 5(1)(e), storage limitation specifies that “personal data shall be kept for no longer than is necessary for the purposes for which the personal data are processed. Personal data may be stored for longer periods insofar as it will be processed solely for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes in accordance with Article 89(1) of GDPR”.
      </p>
      <p>
        You should be aware that if we delete your Data, it may persist on backup or archival media for legal, tax or regulatory purposes.
      </p>

      {/* Section 10: Your rights */}
      <h3 className="mt-5">10. Your rights</h3>
      <p>
        <strong>10.a</strong> You have the following rights in relation to your Data:
      </p>
      <ul>
        <li><strong>Right to be informed</strong> – you have the right to be assured that LPC is processing your data in a clear and transparent manner, that when you provide data to us we are processing it in the way we say we are and that we will not perform any processing which has not been fully explained.</li>
        <li><strong>Right to access</strong> - the right to request copies of the information we hold about you at any time, or that we modify, update or delete such information. If we provide you with access to the information we hold about you, we will not charge you for this, unless your request is "manifestly unfounded or excessive." Where we are legally permitted to do so, we may refuse your request. If we refuse your request, we will tell you the reasons why.</li>
        <li><strong>Right to rectification</strong> - this is the right to have your Data corrected if it is inaccurate or incomplete.</li>
        <li><strong>Right to erasure</strong> - the right to request that we delete or remove your Data from our systems.</li>
        <li><strong>Right to restrict our processing of your Data</strong> - the right to "block" us from using your Data or limit the way in which we can use it.</li>
        <li><strong>Right to data portability</strong> - the right to obtain and reuse your data for your own purposes, from our environment to another in a safe, secure, and usable manner.</li>
        <li><strong>Right to object</strong> - the right to object to our use of your Data including where we use it for our legitimate interests.</li>
        <li><strong>Rights relating to automated decision making and profiling</strong> – relevant particularly in relation to the website.</li>
      </ul>
      <p>
        <strong>10.b</strong> To make enquiries, exercise any of your rights set out above, or withdraw your consent to the processing of your Data (where consent is our legal basis for processing your Data), please contact us via this e-mail address: <a href="mailto:localprimarycare@nhs.net">localprimarycare@nhs.net</a>.
      </p>
      <p>
        <strong>10.c</strong> If you are not satisfied with the way a complaint you make in relation to your Data is handled by us, you may be able to refer your complaint to the relevant data protection authority. For the UK, this is the Information Commissioner's Office (ICO). The ICO's contact details can be found on their website at <a href="https://ico.org.uk/">https://ico.org.uk/</a>.
      </p>
      <p>
        <strong>10.d</strong> It is important that the Data we hold about you is accurate and current. Please keep us informed if your Data changes during the period for which we hold it.
      </p>

      {/* Section 11: Cookies */}
      <h3 className="mt-5">11. Cookies</h3>
      <p>
        <strong>11.a</strong> All Cookies used by the LPC Website are used in accordance with UK The ePrivacy Directive, also known as the European Union (EU) cookie law.
      </p>
      <p>
        <strong>11.b</strong> Before the Website places Cookies on your computer, you will be presented with a message bar or a pop-up window, requesting your consent to set those Cookies. By giving your consent to the placing of Cookies, you are enabling LPC to provide a better experience and service to you.
      </p>
      <p>
        <strong>11.c</strong> You may, if you wish, deny consent to the placing of Cookies; however certain features of the Website may not function fully or as intended. You can choose to enable or disable Cookies in your internet browser. By default, most internet browsers accept Cookies, but this can be changed. For further details, please consult the help menu in your internet browser.
      </p>

      {/* Section 12: Links to other Websites */}
      <h3 className="mt-5">12. Links to other Websites</h3>
      <p>
        The LPC Website may, from time to time, provide links to other websites. We have no control over such websites and are not responsible for the content of these websites or how they manage data provided to them. This privacy policy does not extend to your use of such websites. You are advised to read the privacy policy or statement of other websites prior to using them.
      </p>

      {/* Section 13: Changes of Business Ownership and control */}
      <h3 className="mt-5">13. Changes of Business Ownership and control</h3>
      <p>
        <strong>13.a</strong> Local Primary Care Federation may, from time to time, expand or reduce our business and this may involve the sale and/or the transfer of control of all or part of LPC.
      </p>
      <p>
        <strong>13.b</strong> Data provided by Users will, where it is relevant to any part of our business so transferred, be transferred along with that part and the new owner or newly controlling party will, under the terms of this privacy policy, be permitted to use the Data only for the purposes for which it was originally supplied to us.
      </p>
      <p>
        <strong>13.c</strong> We may also disclose Data to a prospective purchaser of our business or any part of it. In the above instances, we will take steps with the aim of ensuring your privacy is protected under the terms of UK GDPR and the Data Protection Act 2018.
      </p>

      {/* Section 14: General */}
      <h3 className="mt-5">14. General</h3>
      <p>
        <strong>14.a</strong> You may not transfer any of your rights under this privacy policy to any other person. We may transfer our rights under this privacy policy where we reasonably believe your rights will not be affected.
      </p>
      <p>
        <strong>14.b</strong> If any court or competent authority finds that any provision of this privacy policy (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of this privacy policy will not be affected.
      </p>
      <p>
        <strong>14.c</strong> Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be deemed a waiver of that, or any other, right or remedy.
      </p>
      <p>
        <strong>14.d</strong> This Agreement will be governed by and interpreted according to the law of England and Wales. All disputes arising under the Agreement will be subject to the exclusive jurisdiction of the English and Welsh courts.
      </p>

      {/* Section 15: Changes to this Privacy Policy */}
      <h3 className="mt-5">15. Changes to this Privacy Policy</h3>
      <p>
        LPC reserves the right to change this privacy policy as we may deem necessary from time to time or as may be required by law. Any changes will be immediately posted in hard copy in our premises and on the Website and you are deemed to have accepted the terms of the privacy policy on your first use of our services or on accessing the Website following the alterations.
      </p>
      <p className='mb-5'>
        You may contact LPC by email in relation to this Privacy Notice at <a style={{color: "black"}} href="mailto:localprimarycare@nhs.net">localprimarycare@nhs.net</a>.
      </p>
    </div>
    </section>
    </>

  );
};

export default PrivacyPolicy;