import React, { useEffect, useState } from 'react';
// import ServicesSideSection from './ServicesSideSection'; // Assuming ServicesSideSection is another React component
import '../../assets/bootstrap/bootstrap.min.css'
import '../../assets/css/aos.css'
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import img1 from "../../assets/images/Homeimages/enhanced.webp"
import ServiceSideSection from '../serviceSideSection';
import BannerDynammic from '../BannerD';
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';


const EnhancedAccess = () => {
 
  
  const { id } = useParams();

  const [data, setData] = useState(null);

  const encryptToken = (token, key, iv) => {
      const keyHex = CryptoJS.enc.Utf8.parse(key);
      const ivHex = CryptoJS.enc.Utf8.parse(iv);
      const encrypted = CryptoJS.AES.encrypt(token, keyHex, { iv: ivHex, mode: CryptoJS.mode.CBC });
      return encrypted.toString();
    };
    const key = '8347383272422213';
    const iv = '1234567890123456';
    const token = 'Pass.lpc#webitaltech';

    const encryptedToken = encryptToken(token, key, iv);
  useEffect(() => {
    window.scrollTo(0, 0);

      const fetchData = async () => {
        try {
          const response = await fetch(`https://localprimarycare.co.uk/api/Get-LPC-Data.php?action=getServicesDetail&id=${id}`,
            {
              method: 'GET',
              headers: {
                Authorization: encryptedToken,
                'Content-Type': 'application/json',
              },
              referrerPolicy: 'no-referrer',
            }
          );
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const resp = await response.json();
          if (resp.status === 'error') {
            console.error('Error:', resp.message);
          } else {
            setData(resp.data.data[0]);
            // console.log(resp);
            
          }
        } catch (err) {
          console.log(err.message);
        } finally {
          // setLoading(false);
        }
      };
      fetchData();
    }, [id]);

    
    const replaceSpecialCharacters = (text) => {
      if (!text) return text;
    
      // Replace specific characters that have turned into '?'
      return text
        .replace(/\?/g, "'")  // Replace ? with single quote
        .replace(/â€™/g, "'")  // Replace encoding issues for single quote
        .replace(/â€œ/g, '"')  // Replace encoding issues for double quote
        .replace(/â€/g, '"');  // Handle any other character issues
    };
// console.log(data);
const cleanDescription = data?.description
  ?.replace(/\\r\\n|[\r\n]+/g, ' ') // Handle escaped `\r\n` and real new lines
  .replace(/&nbsp;/g, ' ') // Replace non-breaking spaces
  .replace(/â€™/g, "'") // Fix encoding for single quotes
  .replace(/â€œ/g, '"') // Fix encoding for double quotes
  .replace(/â€/g, '"') // Handle any other encoding issues
  .trim() || ''; // Default to an empty string if null/undefined




  return (
    <>
<BannerDynammic had1={data?.service}/>
    <section className="singleblog-section blogpage-section">
      <div className="container" >
        <div className="row">
          <div className=" col-md-12 col-sm-12 col-12">
            <div className="main-box">
              <figure className="image1 text-center" data-aos="fade-up" data-aos-duration="700">
                <img src={`https://localprimarycare.co.uk/public/images/service/${data?.image}`} alt={data?.service || 'service image'} className="img-fluid" />
              </figure>
              <div
  className="content1"
  data-aos="fade-up"
  data-aos-duration="700"
  dangerouslySetInnerHTML={{
    __html: cleanDescription
  }}
></div>

            </div>
          </div>
          {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12 column">
          </div> */}
        </div>
      </div>
      <ServiceSideSection />
    </section>
    </>

  );
};

export default EnhancedAccess;
