import React, { useEffect, useState } from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaAngleRight, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import '../../assets/bootstrap/bootstrap.min.css'
// import '../../assets/css/aos.css'
import '../../assets/css/aos.css';
import '../../assets/css/custom.css';
import '../../assets/css/mobile.css';
import '../../assets/css/owl.carousel.css';
import '../../assets/css/custom-style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/special-classes.css';
import logo from '../../assets/images/LPClogo.png';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';


const FooterSection = () => {
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
 const [data, setData] = useState([]);
    // const [data2, setData2] = useState([]);
    // const [data3, setData3] = useState([]);
    // const [data4, setData4] = useState([]);
  
  
    const encryptToken = (token, key, iv) => {
        const keyHex = CryptoJS.enc.Utf8.parse(key);
        const ivHex = CryptoJS.enc.Utf8.parse(iv);
        const encrypted = CryptoJS.AES.encrypt(token, keyHex, { iv: ivHex, mode: CryptoJS.mode.CBC });
        return encrypted.toString();
      };
    useEffect(() => {
        const key = '8347383272422213';
        const iv = '1234567890123456';
        const token = 'Pass.lpc#webitaltech';
    
        const encryptedToken = encryptToken(token, key, iv);
        const fetchData = async () => {
          try {
            const response = await fetch(`https://localprimarycare.co.uk/api/Get-LPC-Data.php?action=getServices&limit=${"4"}`,
              {
                method: 'GET',
                headers: {
                  Authorization: encryptedToken,
                  'Content-Type': 'application/json',
                },
                referrerPolicy: 'no-referrer',
              }
            );
    
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }

            const resp = await response.json();
            if (resp.status === 'error') {
              console.error('Error:', resp.message);
            } else {
              // setData();
              // const filteredData1 = resp.data.data.filter(item => item.category === "Clinical Services");
              // const filteredData2 = resp.data.data.filter(item => item.category === "Administrative Services");
              // const filteredData3 = resp.data.data.filter(item => item.category === "Professional Development and Training");
              // const filteredData4 = resp.data.data.filter(item => item.category === "Workforce Support");
  
              // setData(filteredData1);
              // setData2(filteredData2)
              // setData3(filteredData3)
              // setData4(filteredData4)
  
              setData(resp.data.data)

              // console.log(resp);
              
            }
          } catch (err) {
            console.log(err.message);
          } finally {
            // setLoading(false);
          }
        };
        fetchData();
      }, []);

      console.log(data);
      
    return (
        <>
            <section className="footer-main-section w-100 float-left position-relative">
                <div className="container">
                    <div className="">
                        {/* <h4>Subscribe To Our <br /> Newsletter</h4>
                        <div className="form-sec">
                            <input type="email" name="email" id="mail" placeholder="Your Email Address:" />
                            <button type="submit" id="submit" className="sign-up-btn">Sign Up Now</button>
                        </div> */}
                    </div>
                    <div className="footer-inner-sec p-5">
                        <div className="footer-boxes">
                            <div className="footer-logo-con">
                                <a href="index">
                                    <figure>
                                        <img src={logo} width={200} alt="logo" />
                                    </figure>
                                </a>
                                {/* <p>Deleniti aeue corrupti quos dolores quas tias excepturi sint occaecati rupiditate non similique sunt incidunt...</p> */}
                                <div className="social-links" style={{marginTop: "-30px"}}>
                                    <ul className="list-unstyled mb-0 d-flex">
                                    <li><a class="facebook" href="https://www.facebook.com/BlackburnLocalPrimaryCare" target="_blank" ><i class="fab fa-facebook-f"></i></a>
                    </li>
                    <li><a class="twitter"
                            href="https://twitter.com/lpcfederation?lang=en" target="_blank"><i
                                class="fab fa-twitter"></i></a></li>
                    <li class="mb-0"><a class="instagram"
                            href="https://www.instagram.com/lpcfederation/" target="_blank"><i
                                class="fab fa-instagram"></i></a>
                    </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="footer-text-boxes">
                            <h4>About Us</h4>
                            <ul className="mb-0 ms-0 list-unstyled">
                                <li className="position-relative"><Link to={"/about"}><FaAngleRight /> About us</Link></li>
                                <li className="position-relative"><Link to={"/successstories"}><FaAngleRight /> Success Stories</Link></li>
                                <li className="position-relative"><Link to={"/privacypolicy"}><FaAngleRight /> Privacy Policy</Link></li>
                                <li className="position-relative mb-0"><Link to={"/#contact"} onClick={() => scrollToSection('contact')}><FaAngleRight /> Contact us</Link></li>
                            </ul>
                        </div>
                        <div className="footer-text-boxes">
                            <h4>Our Services</h4>
                            <ul className="mb-0 list-unstyled">
                                {data.map((item,ind)=>{
                                    return(<li className="position-relative"><Link to={`/servivedetails/${item.id}`}><FaAngleRight /> {item.service}</Link></li>
                                    )
                                })}
                            
                                {/* <li className="position-relative"><Link to="/secretarialhub"><FaAngleRight /> Secretarial Hub</Link></li>
                                <li className="position-relative"><Link to={"/workflow"}><FaAngleRight /> Workflow Hub</Link></li>
                                <li className="position-relative"><Link to={"/prescriptionhub"}><FaAngleRight /> Prescription Hub</Link></li>
                                <li className="position-relative mb-0"><Link to="/longcovid"><FaAngleRight /> Long Covid Service</Link></li> */}
                            </ul>
                        </div>
                        <div className="footer-text-boxes">
                            <h4>Contact Info</h4>
                            <ul className="mb-0 list-unstyled">
                                <li className="position-relative"><a href="tel:01254961018"><FaPhoneAlt /> 01254 961018</a></li>
                                <li className="position-relative"><a href="mailto:localprimarycare@nhs.net"><FaEnvelope /> localprimarycare@nhs.net</a></li>
                                <li className="position-relative mb-0"><FaMapMarkerAlt /> Blackburn Enterprise Centre, Furthergate. Blackburn, BB1 3HQ</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <div className="copy-rihgt-sec w-100 float-left text-center">
                <p className="mb-0">© Copyright 2025 Local Primary Care. All Rights Reserved.</p>
            </div>
            <a id="button"></a>
        </>
    );
};

export default FooterSection;
